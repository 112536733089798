import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { progressIndicator } from '../HelperFunction'
import { Container, Grid } from 'semantic-ui-react';
import Item from '../../content/Item';

function ResearchFillContent({ data }) {
  const { pathname, hash, key } = useLocation()
	useEffect(() => {
		// if not a hash link, scroll to top
		if (hash === '') {
			window.scrollTo(0, 0)
		}
		// else scroll to id
		else {
    //   console.log('hash', hash);
			setTimeout(() => {
				const id = hash.replace('#', '')
				const element = document.getElementById(id)
				if (element) {
					element.scrollIntoView({ behavior: 'smooth' })
				}
			}, 0)
		}
	}, [pathname, hash, key])

	useEffect(() => {
		progressIndicator()
	}, [])

  const items = data.filter(i => i.link === '/research');

  if (!Array.isArray(items) || items.length === 0) {
    return null; // Return early if no items are found
  }

  const sortedItems = items.sort((a, b) => a.order - b.order);
  const listItems = sortedItems.map((item, index) => (
    <Item key={index} item={item} />
  ));

  return (
    <Container className='content'>
      <Grid>{listItems}</Grid>
    </Container>
  );
}

export default ResearchFillContent;
