import React from 'react'
import { Grid, Image } from 'semantic-ui-react'
import './Overview.css'
import ReactHtmlParser from 'react-html-parser'

function ListContentView(props) {

	const { contents } = props
	const renderContent = content => {
		const imageTag = content.imageTag ? content.imageTag : 'Figure 1'

		if (!content) return null
		if (!content.imageSource) {
			return (
				<Grid.Column width={content.widthContent}>
					{ReactHtmlParser(content.content)}
				</Grid.Column>
			)
		}

		return (
			<>
				{content.positionImage === 'left' && (
					<>
						<Grid.Column width={content.widthImage}>
							<Image
								alt={content.imageAlt}
								src={content.imageSource}
								label={{
									color: 'blue',
									content: <span>{imageTag}</span>,
									ribbon: true,
								}}
								size={content.imageSize ? content.imageSize : 'medium'}
								rounded
							/>
						</Grid.Column>
						<Grid.Column width={content.widthContent}>
							{ReactHtmlParser(content.content)}
						</Grid.Column>
					</>
				)}

				{content.positionImage === 'right' && (
					<>
						<Grid.Column width={content.widthContent}>
							{ReactHtmlParser(content.content)}
						</Grid.Column>
						<Grid.Column width={content.widthImage}>
							<Image
								alt={content.imageAlt}
								src={content.imageSource}
								label={{
									color: 'blue',
									content: <span>{imageTag}</span>,
									ribbon: true,
								}}
								size={content.imageSize ? content.imageSize : 'medium'}
								rounded
							/>
						</Grid.Column>
					</>
				)}
			</>
		)
	}

	return (
		<>
			{contents &&
				Array.isArray(contents) &&
				contents.length > 0 &&
				contents.map((content, index) => (
					<React.Fragment key={index}>{renderContent(content)}</React.Fragment>
				))}
		</>
	)
}

export default ListContentView
