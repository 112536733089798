import React from 'react'
import { Container, Button, Image } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'

function OneStaffByData({ data, linkURL, ...props }) {
	const { isStaff } = props
	// const allStaff = isStaff
	// 	? data.find(i => i.link === '/about-us/staff')
	// 	: data.find(i => i.link === '/about-us/board-members')
	// const item =
	// 	allStaff && allStaff.data && Array.isArray(allStaff.data)
	// 		? allStaff.data.find(i => i.linkURL === linkURL)
	// 		: null
	const item = data.find(i => (isStaff ? i.link === '/about-us/staff' : i.link === '/about-us/board-members') && i.linkURL === linkURL)
	return item ? (
		<Container className='content'>
			<h1 className='mb-25'>
				{item.name}
				<br />
				<span style={{ fontSize: '22px' }}>
					{item.infoHeader ? ReactHtmlParser(item.infoHeader) : <em>{item.title}</em>}
				</span>
			</h1>
			<Image src={item.imageSource} className={item.imageClazzName} width='250' />
			{ReactHtmlParser(item.content)}
			{isStaff ? (
				<div className='center mt-40'>
					<Button as={Link} to='/about-us/staff' rel='noreferrer'>
						BACK TO STAFF MEMBERS
					</Button>
				</div>
			) : (
				<div className='center mt-40'>
					<Button as={Link} to='/about-us/board-members' rel='noreferrer'>
						BACK TO BOARD MEMBERS
					</Button>
				</div>
			)}
			<div className='PXE-hr'></div>
		</Container>
	) : null
}

export default OneStaffByData
