import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { progressIndicator } from '../HelperFunction'
import { Container, Grid } from 'semantic-ui-react'
import ReactHtmlParser from 'react-html-parser'

function PrivacyPolicyFillContent({ data }) {
	const { pathname, hash, key } = useLocation()
	useEffect(() => {
		// if not a hash link, scroll to top
		if (hash === '') {
			window.scrollTo(0, 0)
		}
		// else scroll to id
		else {
			// console.log('hash', hash);
			setTimeout(() => {
				const id = hash.replace('#', '')
				const element = document.getElementById(id)
				if (element) {
					element.scrollIntoView({ behavior: 'smooth' })
				}
			}, 0)
		}
	}, [pathname, hash, key])

	useEffect(() => {
		progressIndicator()
	}, [])
	const items = data.filter(i => i.link === '/privacy-policy')
	if (!items || (Array.isArray(items) && items.length === 0)) return null

	if (items && Array.isArray(items)) {
		items.sort((a, b) => a.order - b.order)
	}

	const listContents = []
	for (let index = 1; index < items.length; index++) {
		const element = items[index]
		listContents.push(
			<>
				<h4 id={element.id}>{element.header}</h4>
				{ReactHtmlParser(element.content)}
			</>
		)
	}

	return (
		<>
			<Container className='content'>
				<Grid>
					<Grid.Column width={16}>
						<h1>{items && items[0] && items[0].header}</h1>
					</Grid.Column>
					<Grid.Column width={16}>
						{items && items[0] && items[0].content
							? ReactHtmlParser(items[0].content)
							: null}
						{listContents}
					</Grid.Column>
				</Grid>
				<div className='PXE-hr'></div>
			</Container>
		</>
	)
}

export default PrivacyPolicyFillContent
