import React from 'react'
import { Button, Container, Grid, Segment } from 'semantic-ui-react'
import './Events.css'

function Events() {
	return (
		<>
			<Container className='content'>
				<Grid columns={1}>
					<Grid.Column>
						<h1>PXE International Events</h1>
					</Grid.Column>
					<Grid.Column>
						<Segment raised>
							<h2><s>January 12-14, 2024</s></h2>
								<Button.Content visible>
									The Global Conference has been postponed, new details coming soon.
								</Button.Content>
						</Segment>
					</Grid.Column>
					<div className='PXE-hr'></div>
					<h2>Past Events</h2>
					<Grid.Column>
						<Segment raised>
							<h2>January 13-15 2023</h2>
							<a
								className='ui button fluid gray animated fade'
								href='https://www.youtube.com/playlist?list=PLXD_UPvAflu6DApqIHNdsggtOOoiwZ6jI'
								target='_blank'
								rel='noreferrer'
							>
								<Button.Content visible>
									YouTube Recordings for 2023 Virtual Conference
								</Button.Content>
								<Button.Content hidden>Click here to learn more</Button.Content>
							</a>
						</Segment>
					</Grid.Column>
					<Grid.Column>
						<Segment raised>
							<h2>November 2021</h2>
							<a
								className='ui button fluid gray animated fade'
								href='https://www.youtube.com/playlist?list=PLXD_UPvAflu7EhD5U4Q8dcH_vzPyDSD81'
								target='_blank'
								rel='noopener noreferrer'
							>
								<Button.Content visible>
									YouTube Recordings for 2021 Virtual Conference
								</Button.Content>
								<Button.Content hidden>Click here to learn more</Button.Content>
							</a>
						</Segment>
					</Grid.Column>
					<Grid.Column>
						<Segment raised>
							<h2>November 2020</h2>
							<a
								className='ui button fluid gray animated fade'
								href='https://www.youtube.com/playlist?list=PLXD_UPvAflu5KIVCQgt01IXvffjOjB_zs'
								target='_blank'
								rel='noopener noreferrer'
							>
								<Button.Content visible>
									YouTube Recordings for 2020 Virtual Conference
								</Button.Content>
								<Button.Content hidden>Click here to learn more</Button.Content>
							</a>
						</Segment>
					</Grid.Column>
				</Grid>
				<div className='PXE-hr'></div>
			</Container>
		</>
	)
}

export default Events
