import React from 'react'
import { Container, Grid, List, Button, Segment } from 'semantic-ui-react'

function Meeting() {
	return (
		<>
			<Container>
				<Grid columns={1}>
					<Grid.Column>
						<h1>PXE International Conference 2021 Virtual Event</h1>
					</Grid.Column>
					<Grid.Column>
						<p>
                        Every two years, since 1997, PXE International has held a global conference for people affected by PXE and their loved ones. This year, like last year, will be entirely online.
						</p>

						<p>We are aware that it is easy to make the workshops convenient to all when we are in person. We are all in one time zone, usually eastern US time. For the online meeting, we will all be in the comfort of our homes, we will not pay to travel, and we will not have to pay for hotels. However, we will all be in our time zones – and we span the globe. We thought long and hard about this, and it is inevitable that the sessions are not placed at good times for everyone in every time zone. Perhaps for those not in a convenient time zone, you can pretend you flew to Philly and put yourself on eastern US time? We are kidding, but we are truly sorry about the inconvenience of some of this meeting.</p>
                        <p>All talks will be recorded, and provided there are no technical glitches, we will have them up for you to watch a few hours after each session is concluded.</p>
                        <p>We are aware that it is hard to be on Zoom all day or night – many of us are now on the screen for work all the time. We will break this up with movement and visualization times. We recommend that sometimes you turn off your video and just listen so that you can walk around, stretch, make meals, take a nap, and so on. Last year, we had a lot of great people meet other wonderful people and friendships were formed!</p>
                        <Button fluid size='massive' color='blue' href="https://forms.gle/gpYktNy69sqH8cNcA" target="_blank">Register Here</Button>
                        <div className='PXE-hr'></div>
                        <h2>Zoom tips: things you will want to know how to do</h2>
                        <List as='ul'>
                            <List.Item as='li'>Update your Zoom software to the most recent version just before the meeting</List.Item>
                            <List.Item as='li'>Rename yourself after entering type your name and where you live.</List.Item>
                            <List.Item as='li'>Turn your video on and off.</List.Item>
                            <List.Item as='li'>Mute and unmute yourself.</List.Item>
                            <List.Item as='li'>Raise and lower your hand.</List.Item>
                            <List.Item as='li'>Use the chat.</List.Item>
                            <List.Item as='li'>Show gallery view, show up to 49 people on your screen, and show speaker view.</List.Item>
                            <List.Item as='li'>Show the speaker view so you can see the speaker better.</List.Item>
                            <List.Item as='li'>Pin someone if you want to see them more clearly.</List.Item>
                            <List.Item as='li'>Go into a breakout room and come back to the main room.</List.Item>
                        </List>
                        <div className='PXE-hr'></div>
                        </Grid.Column>
                        <Grid.Column textAlign="center">
                        <h2>Conference Schedule</h2>
					</Grid.Column>
                        </Grid>
                        <Grid centered columns={2} stackable>
                    <Grid.Column>
                        <Segment basic>
                            <h2>North and South America Friendly Hours</h2>
                            <Segment.Group>
                                <Segment padded color='blue'>
                                <h3>Friday, November 5th</h3>
                                </Segment>
                                <Segment padded>
                                <h4>4 PM PT/7 PM ET (2 hours)</h4>
                                <h4>Start of the Conference, Overview of the weekend, Meet and Greet</h4>
                                <p>In this session, you will have the opportunity to meet other people, share your experience of living with PXE or living with someone with PXE and get to know some of the people with whom you will be sharing the next few days.</p>
                                </Segment>
                            </Segment.Group>
                            <Segment.Group>
                                <Segment padded color='blue'>
                                <h3>Saturday, November 6th</h3>
                                </Segment>
                                <Segment padded>
                                <h4>7 AM PT/10 AM ET (3 hours)</h4>
                                <p>Overview of PXE<br />PXE and Skin<br /> PXE and Eye</p>
                                </Segment>
                                <Segment padded>
                                <h4>10 AM PT/1 PM ET (1 hour)</h4>
                                <h4>Break - networking available during this time</h4>
                                <p>You can join others in a breakout room - bring your late breakfast or lunch and chat while you eat.</p>
                                </Segment>
                                <Segment padded>
                                <h4>11 AM PT/2 PM ET (3 hours)</h4>
                                <p>PXE and Genetics <br /> PXE and Vascular <br />General Questions and Answers</p>
                                </Segment>
                                <Segment padded>
                                <h4>2 PM PT/5 PM ET (Unstructured time)</h4>
                                <h4>Unmoderated Open Time</h4>
                                <p>We will open the breakout rooms - you can ask to be in a room by region, or by interests</p>
                                </Segment>
                            </Segment.Group>
                            <Segment.Group>
                                <Segment padded color='blue'>
                                <h3>Sunday, November 7th</h3>
                                </Segment>
                                <Segment padded>
                                <h4>7 AM PT/10 AM ET (4 hours including breaks)</h4>
                                <h4>Updates from PXE researchers from around the world</h4>
                                <p></p>
                                </Segment>
                                <Segment padded>
                                <h4>11 AM PT/2 PM ET</h4>
                                <h4>Closing - onward we go!</h4>
                                <p>Networking - breakout rooms available<br /> We will open breakout rooms - you can ask to be in a room by country/region/ interests <br /> This will be an unmoderated open time.</p>
                                </Segment>
                            </Segment.Group>
                        </Segment>
                        
                    </Grid.Column>
                    <Grid.Column>
                    <Segment basic>
                            <h2>European Friendly Hours</h2>
                            <Segment.Group>
                                <Segment padded color='blue'>
                                <h3>Friday, November 5th</h3>
                                </Segment>
                                <Segment padded>
                                <h4>6 PM GMT/7 PM CET (2 hours)</h4>
                                <h4>Start of the Conference, Overview of the weekend, Meet and Greet</h4>
                                <p>In this session, you will have the opportunity to meet other people, share your experience of living with PXE or living with someone with PXE and get to know some of the people with whom you will be sharing the next few days.</p>
                                </Segment>
                            </Segment.Group>
                            <Segment.Group>
                                <Segment padded color='blue'>
                                <h3>Saturday, November 6th</h3>
                                </Segment>
                                <Segment padded>
                                <h4>2 PM GMT/3 PM CET (3 hours)</h4>
                                <p>Overview of PXE<br />PXE and Skin<br /> PXE and Eye</p>
                                </Segment>
                                <Segment padded>
                                <h4>5 PM GMT/6 PM CET (1 hour)</h4>
                                <h4>Break - networking available during this time</h4>
                                <p>You can join others in a breakout room - bring your late breakfast or lunch and chat while you eat.</p>
                                </Segment>
                                <Segment padded>
                                <h4>6 PM GMT/7 PM CET (3 hours)</h4>
                                <p>PXE and Genetics <br /> PXE and Vascular <br />General Questions and Answers</p>
                                </Segment>
                                <Segment padded>
                                <h4>9 PM GMT/10 PM CET (Unstructured time)</h4>
                                <h4>Unmoderated Open Time</h4>
                                <p>We will open the breakout rooms - you can ask to be in a room by region, or by interests</p>
                                </Segment>
                            </Segment.Group>
                            <Segment.Group>
                                <Segment padded color='blue'>
                                <h3>Sunday, November 7th</h3>
                                </Segment>
                                <Segment padded>
                                <h4>3 PM GMT/4 PM CET (4 hours including breaks)</h4>
                                <h4>Updates from PXE researchers from around the world</h4>
                                <p></p>
                                </Segment>
                                <Segment padded>
                                <h4>6 PM GMT/7 PM CET</h4>
                                <h4>Closing - onward we go!</h4>
                                <p>Networking - breakout rooms available<br /> We will open breakout rooms - you can ask to be in a room by country/region/ interests <br /> This will be an unmoderated open time.</p>
                                </Segment>
                            </Segment.Group>
                        </Segment>
                    </Grid.Column>
				</Grid>
				<div className='PXE-hr'></div>
			</Container>
		</>
	)
}

export default Meeting
