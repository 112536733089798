import React from 'react'
import { Container, Button } from 'semantic-ui-react'

function ContactUs() {
	return (
		<Container className='content'>
			<h1>Contact Us</h1>
			<p>
				Contact Mary for more information{' '}
				<a href='mailto:mary@pxe.org'>mary@pxe.org</a> or 202.362.9599 x208
			</p>
			<Button
				color='blue'
				fluid
				animated='fade'
				href='https://forms.gle/a61P3wKrSwSYKyyz9'
				target='_blank'
			>
				<Button.Content visible className='mb-5 mt-5'>
					Register with PXE
				</Button.Content>
				<Button.Content hidden className='mb-5 mt-5'>
					Thank you for your participation!
				</Button.Content>
			</Button>
			<div className='PXE-hr'></div>
		</Container>
	)
}

export default ContactUs
