import React from 'react'
import { Container, Grid, Segment, Button, Icon } from 'semantic-ui-react'
import './Home.css'
import ReactHtmlParser from 'react-html-parser'

function HomeFillContent({ data }) {
	const items = data.filter(i => i.link === '/')
	console.log(items)
	if (items && Array.isArray(items) && items.length > 0) {
		items.sort((a, b) => a.index - b.index)
	} else {
		return null
	}
	return (
		<>
			<Container className='content'>
				<h1>{items && items[0].header}</h1>

				{items && items[0].content && ReactHtmlParser(items[0].content)}
				<Grid centered columns={3} stackable padded>
					<Grid.Column>
						<Segment raised textAlign='center'>
							<h3>{items && items[1].header}</h3>
							<p className='h-120'>
								{items && items[1].content && ReactHtmlParser(items[1].content)}
							</p>
							<Button
								basic
								size='huge'
								color='blue'
								href={items && items[1].href}
								target='_blank'
							>
								{items && items[1].contentButton}{' '}
								<Icon fitted name='external square alternate' />
							</Button>
						</Segment>
					</Grid.Column>
					<Grid.Column>
						<Segment raised textAlign='center'>
							<h3>{items && items[2].header}</h3>
							<p>{items && items[2].content && ReactHtmlParser(items[2].content)}</p>
							<Button basic size='huge' color='blue' href={items && items[2].href}>
								{items && items[2].contentButton}
							</Button>
						</Segment>
					</Grid.Column>
					<Grid.Column>
						<Segment raised textAlign='center'>
							<h3>{items && items[3].header}</h3>
							<p>{items && items[3].content && ReactHtmlParser(items[3].content)}</p>
							<Button
								basic
								size='huge'
								color='blue'
								href={items && items[3].href}
								target='_blank'
							>
								{items && items[3].contentButton}{' '}
								<Icon fitted name='external square alternate' />
							</Button>
						</Segment>
					</Grid.Column>
				</Grid>
			</Container>
		</>
	)
}
export default HomeFillContent
