export const rightArrow = (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M23.4144 12.0002L15.0002 3.58594L13.5859 5.00015L19.5858 11H2V13H19.5861L13.5859 19.0002L15.0002 20.4144L23.4144 12.0002Z'
			fill='#052C53'
		/>
	</svg>
)

export const SearchIcon = (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 11.8861 16.2541 13.5979 15.0412 14.8566L14.8566 15.0412C13.5979 16.2541 11.8861 17 10 17C6.13401 17 3 13.866 3 10ZM15.6177 17.0319C14.078 18.2635 12.125 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10C19 12.125 18.2635 14.078 17.0319 15.6177L22.7071 21.2929L23.4142 22L22 23.4142L21.2929 22.7071L15.6177 17.0319Z'
			fill='#052C53'
		/>
	</svg>
)
