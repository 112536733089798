import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { progressIndicator } from '../HelperFunction'
import { Container, Grid, Label, List, Segment } from 'semantic-ui-react'
import './Overview.css'
import ListItems from './ListItems'
import ListViews from './ListViews'
import ReactHtmlParser from 'react-html-parser'

function OverviewFillContent({ data }) {
	const { pathname, hash, key } = useLocation()
	useEffect(() => {
		// if not a hash link, scroll to top
		if (hash === '') {
			window.scrollTo(0, 0)
		}
		// else scroll to id
		else {
			setTimeout(() => {
				const id = hash.replace('#', '')
				const element = document.getElementById(id)
				if (element) {
					element.scrollIntoView({ behavior: 'smooth' })
				}
			}, 0)
		}
	}, [pathname, hash, key])

	useEffect(() => {
		progressIndicator()
	}, [])

	const overview = data.filter(
		i => i.link === '/overview' && i.part === 'Content'
	)
	const overviewTop = data.find(i => i.link === '/overview' && i.part === 'Top')
	const overViewQuickLink1 = data.filter(
		i => i.link === '/overview' && i.linkSub === '/overview-quick-link-1'
	) // list quick link first column
	const overViewQuickLink2 = data.filter(
		i => i.link === '/overview' && i.linkSub === '/overview-quick-link-2'
	) // list quick link second column
	const overViewBulletins1 = data.filter(
		i => i.link === '/overview' && i.linkSub === '/overview-bulletins-1'
	) // list bulletins first column
	const overViewBulletins2 = data.filter(
		i => i.link === '/overview' && i.linkSub === '/overview-bulletins-2'
	) // list bulletins second column
	return (
		<>
			<Container className='content'>
				<Grid columns={1}>
					<Grid.Column>
						<h1>{overviewTop && overviewTop.header}</h1>
					</Grid.Column>
					<Grid.Column>
						{overviewTop &&
							overviewTop.content &&
							ReactHtmlParser(overviewTop.content)}
					</Grid.Column>
				</Grid>
				<Grid columns={2} stackable padded>
					<Grid.Column>
						<Segment.Group compact textAlign='center' horizontal>
							<Segment>
								<List link className='content bold overview-quicklinks'>
									<ListItems listItems={overViewQuickLink1} />
								</List>
							</Segment>
							<Segment>
								<List link className='content bold overview-quicklinks mt-50'>
									<ListItems listItems={overViewQuickLink2} />
								</List>
							</Segment>
							<Label attached='top' className='content'>
								Quick Links
							</Label>
						</Segment.Group>
					</Grid.Column>
					<Grid.Column>
						<Segment.Group compact textAlign='center' horizontal>
							<Segment>
								<List link className='content bold overview-bulletins mt-50'>
									<ListItems listItems={overViewBulletins1} />
								</List>
							</Segment>
							<Segment>
								<List link className='content bold overview-bulletins'>
									<ListItems listItems={overViewBulletins2} />
								</List>
							</Segment>
							<Label attached='top' className='content'>
								Bulletins
							</Label>
						</Segment.Group>
					</Grid.Column>
				</Grid>
				<Grid columns={1}>
					<ListViews views={overview && overview} />
				</Grid>
				<div className='PXE-hr'></div>
			</Container>
		</>
	)
}

export default OverviewFillContent
