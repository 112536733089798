import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { progressIndicator } from '../HelperFunction'
import { Container, Grid } from 'semantic-ui-react'
import ReactHtmlParser from 'react-html-parser'
import ListContentHistory from './ListContentHistory'

function AboutUsFillContent({ data }) {
	// const [activeIndex, setActiveIndex] = useState(0)
	// const handleAccordion = (e, index) => {
	// 	setActiveIndex(activeIndex === index.index ? -1 : index.index)
	// }
	// const [sliderIndex, setSliderIndex] = useState(0)
	const { pathname, hash, key } = useLocation()
	useEffect(() => {
		// if not a hash link, scroll to top
		if (hash === '') {
			window.scrollTo(0, 0)
		}
		// else scroll to id
		else {
			setTimeout(() => {
				const id = hash.replace('#', '')
				const element = document.getElementById(id)
				if (element) {
					element.scrollIntoView({ behavior: 'smooth' })
				}
			}, 0)
		}
	}, [pathname, hash, key])

	useEffect(() => {
		progressIndicator()
	}, [])

	// const handleLeftSlider = () => {
	// 	const slider = document.querySelector(".slider-aspects")
	// 	const sliderIndex = parseInt(getComputedStyle(slider).getPropertyValue("--slider-index"))
	// 	if (sliderIndex === 0) {
	// 		return null
	// 	}
	// 	slider.style.setProperty("--slider-index", sliderIndex - 1)
	// 	setSliderIndex(sliderIndex - 1)
	// }
	// const handleRightSlider = () => {
	// 	const slider = document.querySelector(".slider-aspects")
	// 	const sliderIndex = parseInt(getComputedStyle(slider).getPropertyValue("--slider-index"))
	// 	if (sliderIndex === 6) {
	// 		return null
	// 	}
	// 	slider.style.setProperty("--slider-index", sliderIndex + 1)
	// 	setSliderIndex(sliderIndex + 1)
	// }
	// console.log('data', data)
	const aboutUs = data.filter(i => i.link === '/about-us' && i.part === 'Top')
	// console.log('aboutUs', aboutUs)
	if (!aboutUs || (Array.isArray(aboutUs) && aboutUs.length === 0)) return null

	if (aboutUs && Array.isArray(aboutUs)) {
		aboutUs.sort((a, b) => a.order - b.order)
	}
	const aboutUsHistory = data.filter(i => i.link === '/about-us' && i.part === 'History')
	if (aboutUsHistory && Array.isArray(aboutUsHistory)) {
		aboutUsHistory.sort((a, b) => a.order - b.order)
	}
	return (
		<>
			<Container className='content'>
				<Grid columns={1}>
					<Grid.Column>
						<h1>{aboutUs[0].header}</h1>
					</Grid.Column>
					<Grid.Column>{ReactHtmlParser(aboutUs[0].content)}</Grid.Column>
					<Grid.Column>
						<h4>{aboutUs[1].header}</h4>

						<ul className='list-right list-space list-check'>
							{ReactHtmlParser(aboutUs[1].content)}
						</ul>
					</Grid.Column>
					<Grid.Column>{ReactHtmlParser(aboutUs[2].content)}</Grid.Column>
					<div className='PXE-hr'></div>
					<Grid.Column>
						<h3 id='PXE_History'>{aboutUs[3].header}</h3>
						{ReactHtmlParser(aboutUs[3].content)}

						{/* <ul className='list-right list-space'> */}
						{/* {ReactHtmlParser(aboutUs.historyList)} */}
						{/* </ul> */}

						<ListContentHistory contents={aboutUsHistory} />
					</Grid.Column>
				</Grid>
				<div className='PXE-hr'></div>
			</Container>
		</>
	)
}

export default AboutUsFillContent
