import React from 'react'
import { Container } from 'semantic-ui-react'
import '../staff/Staff.css'
import { Link } from 'react-router-dom'

function BoardMembersFillContent({ data }) {
	if (data === undefined || data === null) return <></>

	const listStaffs = data.filter(i => i.link === '/about-us/board-members')

	if (listStaffs === undefined || listStaffs === null) return <></>

	if (listStaffs && Array.isArray(listStaffs)) {
		listStaffs.sort((a, b) => a.order - b.order)
	}

	return (
		<>
			<Container className='content'>
				<h1 className='mb-25'>Board Members</h1>
				{listStaffs.map((staff, index) => {
					return (
						<ul className='list-right list-space'>
							<li key={index}>
								<Link to={staff.linkURL} rel='noreferrer'>
									{staff.name}
								</Link>
								, {staff.title}
							</li>
						</ul>
					)
				})}
				<div className='PXE-hr'></div>
			</Container>
		</>
	)
}
export default BoardMembersFillContent
