import React from 'react';
import { Button } from 'semantic-ui-react';
import ReactHtmlParser from 'react-html-parser';
import { scrollToTop } from '../../helpers/ScrollToTop';

function ListContentView(props) {
    const { contents } = props;

    const renderContent = (content) => {

        if (!content) return null;
        if (content.lastestNews) {
            return (
                <>
                    <h5 id={content.id}>{content.header}</h5>
                    {ReactHtmlParser(content.content)}
                </>
            );
        }

        return (
            <>
                <h5 id={content.id}>{content.header}</h5>
                {ReactHtmlParser(content.content)}
                <Button onClick={scrollToTop}>RETURN TO TOP</Button>
            </>
        );
    };

    return (
        <>
            {contents && Array.isArray(contents) && contents.length > 0 && contents.map((content, index) => (
                <React.Fragment key={index}>
                    {renderContent(content)}
                </React.Fragment>
            ))}
        </>
    );
}

export default ListContentView;
