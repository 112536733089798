import React from 'react'
import { Image, Message } from 'semantic-ui-react'
import Navbar from './navigation/Navbar'
import { Link } from 'react-router-dom'

function Header() {
	let message = '' // Set a message here to display it in the header
	return (
		<>
			{message !== '' && (
				<Message
					className='announcement-bar'
					style={{ backgroundColor: '#294761' }}
				>
					<Message.Content style={{ textAlign: 'center', color: 'white' }}>
						{message}
					</Message.Content>
				</Message>
			)}
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					maxWidth: '1120px',
					margin: '0 auto',
				}}
			>
				<Link to='/'>
					<Image
						src='/images/structure/logo.png'
						className='logo'
						alt='PXE International, Inc.'
					/>
				</Link>
				<Navbar />
			</div>
		</>
	)
}

export default Header
