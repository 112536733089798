import React from 'react'
import {
	Container,
	Grid,
	Image,
	Segment,
	Button,
	Icon,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'

function News() {
	return (
		<>
			<Container className='content'>
				<Grid stackable className='mb-15 thumbnail-newsletter'>
					<Grid.Column width={16}>
						<h1>PXE International News</h1>
						<Segment raised textAlign='center'>
							<h3>Register with PXE</h3>
							<p>
								You will receive the monthly eNews and information about clinical trials
								and have the opportunity to meet other PXEers.
							</p>
							<Button
								basic
								size='huge'
								color='blue'
								href='https://forms.gle/a61P3wKrSwSYKyyz9'
								target='_blank'
							>
								Register with PXE <Icon fitted name='external square alternate' />
							</Button>
						</Segment>
					</Grid.Column>
					<div className='PXE-hr'></div>
					<Grid.Row>
						<h2 className='text-center-767px'>2023</h2>
					</Grid.Row>
					<Grid.Row columns={5}>
						<Grid.Column>
							<Link
								to='/pdf/news/September-2023-Newsletter.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/September-2023-Newsletter.png'
								/>
								<p className='center'>September 2023</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link
								to='/pdf/news/August-2023-Newsletter.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/August-2023-Newsletter.png'
								/>
								<p className='center'>August 2023</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link
								to='/pdf/news/June-2023-Newsletter.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/June-2023-Newsletter.png'
								/>
								<p className='center'>June 2023</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link
								to='/pdf/news/May-2023-Newsletter.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/May-2023-Newsletter.png'
								/>
								<p className='center'>May 2023</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link
								to='/pdf/news/April-eNews-2023.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/April-eNews-2023.png'
								/>
								<p className='center'>April 2023</p>
							</Link>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row columns={5}>
						<Grid.Column>
							<Link
								to='/pdf/news/March-eNews-2023.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/March-eNews-2023.png'
								/>
								<p className='center'>March 2023</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link
								to='/pdf/news/JanuaryFebruary-eNews-2023.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/JanuaryFebruary-eNews-2023.png'
								/>
								<p className='center'>January/February 2023</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link
								to='/pdf/news/CelebratingJouni.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/CelebratingJouni.png'
								/>
								<p className='center'>Celebrating Jouni 2023</p>
							</Link>
						</Grid.Column>
					</Grid.Row>
					<div className='PXE-hr'></div>
					<Grid.Row>
						<h2 className='text-center-767px'>2022</h2>
					</Grid.Row>
					<Grid.Row columns={5}>
						<Grid.Column>
							<Link
								to='/pdf/news/December-eNews-2022.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/December-eNews-2022.png'
								/>
								<p className='center'>December 2022</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link
								to='/pdf/news/November-eNews-2022.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/November-eNews-2022.png'
								/>
								<p className='center'>November 2022</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link
								to='/pdf/news/September-October-eNews-2022.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/SeptemberOctober-eNews-2022.png'
								/>
								<p className='center'>September, October 2022</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link to='/pdf/news/Aug-2022-eNews.pdf' rel='noreferrer' target='_blank'>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-Aug-2022.png'
								/>
								<p className='center'>August 2022</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link
								to='/pdf/news/July-2022-eNews.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-July-2022.png'
								/>
								<p className='center'>July 2022</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link
								to='/pdf/news/June-2022-eNews.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-Jun-2022.png'
								/>
								<p className='center'>June 2022</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link to='/pdf/news/May-2022-eNews.pdf' rel='noreferrer' target='_blank'>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-May-2022.png'
								/>
								<p className='center'>May 2022</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link
								to='/pdf/news/April-2022-eNews.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-Apr-2022.png'
								/>
								<p className='center'>April 2022</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link
								to='/pdf/news/March-2022-eNews.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-Mar-2022.png'
								/>
								<p className='center'>March 2022</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link
								to='/pdf/news/January-2022-eNews.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-Jan-2022.png'
								/>
								<p className='center'>January 2022</p>
							</Link>
						</Grid.Column>
					</Grid.Row>
					<div className='PXE-hr'></div>
					<Grid.Row>
						<h2 className='text-center-767px'>2021</h2>
					</Grid.Row>
					<Grid.Row columns={5}>
						<Grid.Column>
							<Link
								to='/pdf/news/November-2021-eNews.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-Nov-2021.png'
								/>
								<p className='center'>November 2021</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link
								to='/pdf/news/October-2021-eNews.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-Oct-2021.png'
								/>
								<p className='center'>October 2021</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link
								to='/pdf/news/September-2021-eNews.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-Sept-2021.png'
								/>
								<p className='center'>September 2021</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link
								to='/pdf/news/July-2021-eNews.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-Jul-2021.png'
								/>
								<p className='center'>July 2021</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link
								to='/pdf/news/June-2021-eNews.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-Jun-2021.png'
								/>
								<p className='center'>June 2021</p>
							</Link>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row columns={5}>
						<Grid.Column>
							<Link to='/pdf/news/May-2021-eNews.pdf' rel='noreferrer' target='_blank'>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-May-2021.png'
								/>
								<p className='center'>May 2021</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link
								to='/pdf/news/April-2021-eNews.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-Apr-2021.png'
								/>
								<p className='center'>April 2021</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link
								to='/pdf/news/March-2021-eNews.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-Mar-2021.png'
								/>
								<p className='center'>March 2021</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link
								to='/pdf/news/February-2021-eNews.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-Feb-2021.png'
								/>
								<p className='center'>February 2021</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link
								to='/pdf/news/January-2021-eNews.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-Jan-2021.png'
								/>
								<p className='center'>January 2021</p>
							</Link>
						</Grid.Column>
					</Grid.Row>
					<div className='PXE-hr'></div>
					<Grid.Row>
						<h2 className='text-center-767px'>2020</h2>
					</Grid.Row>
					<Grid.Row columns={5}>
						<Grid.Column>
							<Link
								to='/pdf/news/December-2020-eNews.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-Dec-2020.png'
								/>
								<p className='center'>December 2020</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link
								to='/pdf/news/June-2020-eNews.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-Jun-2020.png'
								/>
								<p className='center'>June 2020</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link to='/pdf/news/May-2020-eNews.pdf' rel='noreferrer' target='_blank'>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-May-2020.png'
								/>
								<p className='center'>May 2020</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link
								to='/pdf/news/March-2020-eNews.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-Mar-2020.png'
								/>
								<p className='center'>March 2020</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link
								to='/pdf/news/February-2020-eNews.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-Feb-2020.png'
								/>
								<p className='center'>February 2020</p>
							</Link>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row columns={5}>
						<Grid.Column>
							<Link
								to='/pdf/news/January-2020-eNews.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-Jan-2020.png'
								/>
								<p className='center'>January 2020</p>
							</Link>
						</Grid.Column>
					</Grid.Row>
					<div className='PXE-hr'></div>
					<Grid.Row>
						<h2 className='text-center-767px'>2019</h2>
					</Grid.Row>
					<Grid.Row columns='5'>
						<Grid.Column>
							<Link
								to='/pdf/news/January-2019-eNews.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-Jan-2019.png'
								/>
								<p className='center'>January 2019</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link
								to='/pdf/news/February-2019-eNews.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-Feb-2019.png'
								/>
								<p className='center'>February 2019</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link
								to='/pdf/news/March-2019-eNews.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-Mar-2019.png'
								/>
								<p className='center'>March 2019</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link
								to='/pdf/news/April-2019-eNews.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-Apr-2019.png'
								/>
								<p className='center'>April 2019</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link to='/pdf/news/May-2019-eNews.pdf' rel='noreferrer' target='_blank'>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-May-2019.png'
								/>
								<p className='center'>May 2019</p>
							</Link>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row columns='5'>
						<Grid.Column>
							<Link
								to='/pdf/news/June-2019-eNews.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-Jun-2019.png'
								/>
								<p className='center'>June 2019</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link
								to='/pdf/news/July-2019-eNews.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-Jul-2019.png'
								/>
								<p className='center'>July 2019</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link
								to='/pdf/news/August-2019-eNews.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-Aug-2019.png'
								/>
								<p className='center'>August 2019</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link
								to='/pdf/news/September-2019-eNews.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-Sep-2019.png'
								/>
								<p className='center'>September 2019</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link
								to='/pdf/news/October-2019-eNews.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-Oct-2019.png'
								/>
								<p className='center'>October 2019</p>
							</Link>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row columns='5'>
						<Grid.Column>
							<Link
								to='/pdf/news/November-2019-eNews.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-Nov-2019.png'
								/>
								<p className='center'>November 2019</p>
							</Link>
						</Grid.Column>
						<Grid.Column>
							<Link
								to='/pdf/news/December-2019-eNews.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='small'
									className='border-1px center'
									src='/images/content/news/eNewsletter-Dec-2019.png'
								/>
								<p className='center'>December 2019</p>
							</Link>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<div className='PXE-hr'></div>
			</Container>
		</>
	)
}

export default News
