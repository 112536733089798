import React from 'react'
import { Container, Grid, Image } from 'semantic-ui-react'

function Financials() {
	return (
		<>
			<Container className='content'>
				<h1 className='mb-25'>PXE Financials</h1>
				<Grid stackable className='mb-15'>
					<Grid.Row columns={4}>
						<Grid.Column className='mb-15'>
							<a
								href='/pdf/financials/PXE9902020_Public.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='medium'
									className='border-1px center'
									src='/images/content/financials/tax-return-2020.png'
								/>
								<p className='center'>2020</p>
							</a>
						</Grid.Column>
						<Grid.Column>
							<a
								href='/pdf/financials/PXE9902019_Public.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='medium'
									className='border-1px center'
									src='/images/content/financials/tax-return-2019.png'
								/>
								<p className='center'>2019</p>
							</a>
						</Grid.Column>
						<Grid.Column>
							<a
								href='/pdf/financials/PXE9902018_Public.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='medium'
									className='border-1px center'
									src='/images/content/financials/tax-return-2018.png'
								/>
								<p className='center'>2018</p>
							</a>
						</Grid.Column>
						<Grid.Column>
							<a
								href='/pdf/financials/PXE9902017_Public.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='medium'
									className='border-1px center'
									src='/images/content/financials/tax-return-2017.png'
								/>
								<p className='center'>2017</p>
							</a>
						</Grid.Column>
						<Grid.Column>
							<a
								href='/pdf/financials/PXE9902016_Public.pdf'
								rel='noreferrer'
								target='_blank'
							>
								<Image
									size='medium'
									className='border-1px center'
									src='/images/content/financials/tax-return-2016.png'
								/>
								<p className='center'>2016</p>
							</a>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<div className='PXE-hr'></div>
			</Container>
		</>
	)
}
export default Financials
