import React from 'react'
import OneView from './OneView'
import './Overview.css'

function ListViews({ views }) {
	const listViews = []
	if (views && Array.isArray(views) && views.length > 0) {
		for (let order = 0; order <= 15; order++) {
			const matchingView = views.find(view => view.order === order)
			if (matchingView) {
				listViews.push(<OneView key={matchingView.order} item={matchingView} />)
			}
		}
	}

	return <>{listViews}</>
}

export default ListViews
