import React from 'react'
import { /* Button ,*/ Container, Grid, /* Segment */ } from 'semantic-ui-react'
// import { Link } from 'react-router-dom'

function GlobalPXEMeeting2022() {
	return (
		<>
			<Container className='content'>
				<Grid columns={1}>
					<Grid.Column>
						<h1>2022 PXE International Conference Virtual</h1>
					</Grid.Column>
					<Grid.Column>
						<div>
							<p>
								Since 1997, PXE International has held a global conference for people affected by PXE and their loved ones. This year, like last year, will be entirely online.
							</p>
							<p>
								We are aware that it is easy to make the workshops convenient to all when we are in person. We are all in one time zone, usually eastern US time. For the online meeting, we will all be in the comfort of our homes, we will not pay to travel, and we will not have to pay for hotels. However, we will all be in our time zones – and we span the globe. We thought long and hard about this, and, inevitably, the sessions are not placed at good times for everyone in every time zone.
							</p>
							<p>
								All talks will be recorded, and provided there are no technical glitches, we will have them up for you to watch in a week.
							</p>
							<p>
								We are aware that it is hard to be on Zoom all day or night – many of us are now on the screen for work all the time. We will break this up with movement and visualization times. We recommend that sometimes you turn off your video and just listen so that you can walk around, stretch, make meals, take a nap, and so on. Last year, we had a lot of great people meet other wonderful people - and friendships were formed!
							</p>
							<p>
								<b>Zoom tips - things you will want to know how to do:</b>
							</p>
							<ul>
								<li>
									Update your Zoom software to the most recent version in advance of the meeting
								</li>
								<li>
									Rename yourself after entering - type your name and where you live.
								</li>
								<li>Turn your video on and off.</li>
								<li>Mute and unmute yourself.</li>
								<li>Raise and lower your hand.</li>
								<li>Use the chat.</li>
								<li>
									Show gallery view, show up to 49 people on your screen, and show speaker view.
								</li>
								<li>Show the speaker view so you can see the speaker better.</li>
								<li>Pin someone if you want to see them more clearly.</li>
								<li>Go into a breakout room and come back to the main room.</li>
							</ul>
							<p>
								Register here:{' '}
								<a
									href='https://forms.gle/4psAd1TfgVtK5GsPA'
									target='_blank'
									rel='noreferrer'
								>
									https://forms.gle/4psAd1TfgVtK5GsPA
								</a>
							</p>
						</div>
						<div className='mt-40'>
							<h3>Conference Schedule</h3>
							<p>
								We schedule the conference so that there are times that are suitable for as many regions as we are able. The schedule includes opening sessions by region and other common sessions (apology for the inconvenient times).
							</p>
							<p><b><u>Friday, January 13th</u></b></p>
							<p className='mb-0'>
								<b>Opening Session (by region)</b>
								<br />
								<b>18 AEST/20 NZST (2 hours) – for Asia, Australia, New Zealand (others welcome)</b>
							</p>
							<p className='ml-2em'>
								<b>Welcome by local PXE support groups, Overview of the weekend, Meet and Greet</b>
								<br />
								In this session, you will have the opportunity to meet other people, share your experience of living with PXE or living with someone with PXE and get to know some of the people with whom you will be sharing the next few days.
							</p>
							<p className='mb-0'>
								<b>19 CET (2 hours) – For Europe (others welcome)</b>
							</p>
							<p className='ml-2em'>
								<b>Welcome by local PXE support groups, Overview of the weekend, Meet and Greet</b>
								<br />
								In this session, you will have the opportunity to meet other people, share your experience of living with PXE or living with someone with PXE and get to know some of the people with whom you will be sharing the next few days.
							</p>
							<p className='mb-0'>
								<b>4 PM PT/7 PM ET (2 hours) – For North America (others welcome)</b>
							</p>
							<p className='ml-2em'>
								<b>Start of the Conference, Overview of the weekend, Meet and Greet</b>
								<br />
								In this session, you will have the opportunity to meet other people, share your experience of living with PXE or living with someone with PXE and get to know some of the people with whom you will be sharing the next few days.
							</p>
							<p><b><u>Saturday, January 14th</u></b></p>
							<p className='mb-0'>
								<b>
									7 AM PT/10 AM ET/16 CET; <span className='color-1e70bf'>1 AEST/4 NZST (Sunday)</span> (1.5 hours)
								</b>
							</p>
							<p className='ml-2em'>
								Overview of PXE - Sharon Terry
								<br />
								PXE and Skin - Lionel Bercovitch, MD
								<br />
								PXE and Eye - Wayne Fuchs, MD
							</p>
							<p className='mb-0'>
								<b>
									8:30 AM PT/11:30 PM ET/17:30 CET; <span className='color-1e70bf'>2:30 AEST/5:30 NZST (Sunday)</span> (30 minutes)
								</b>
							</p>
							<p className='ml-2em'>
								Break - networking available during this time – chat with others in a breakout room
							</p>
							<p className='mb-0'>
								<b>
									9 AM PT/12 PM ET/18 CET; <span className='color-1e70bf'>3 AEST/6 NZST (Sunday)</span> (1.5 hours)
								</b>
							</p>
							<p className='ml-2em'>
								PXE and Vascular – Wilko Spiering, MD, PhD
								<br />
								PXE and the possibility of treatment – Sharon Terry
								<br />
								General Questions and Answers	
							</p>
							<p className='mb-0'>
								<b>
									10:30 AM PT/1:30 PM ET/19:30 CET; <span className='color-1e70bf'>4:30 AEST/7:30 NZST (Sunday)</span> (Unstructured time)
								</b>
							</p>
							<p className='ml-2em'>
								We will open the breakout rooms - you can ask to be in a room by region or by interest. This will be an unmoderated open time.	
							</p>
							<p><b><u>Sunday, January 15th</u></b></p>
							<p className='mb-0'>
								<b>
									7 AM PT/10 AM ET/16 CET; <span className='color-1e70bf'>1 AEST/4 NZDT (Monday)</span> (3 hours, including breaks)
								</b>
							</p>
							<p className='ml-2em mb-0'>
								Updates from PXE Researchers from around the world	
							</p>
							<ul className='ml-2em mt-0'>
								<li>
									Koen van de Wetering, DVM, PhD (Thomas Jefferson University-PXE Center of Excellence &amp; Clinical Care)
								</li>
								<li>Olivier Vanakker, MD, PhD (Ghent University Hospital)</li>
								<li>
									Daniela Quaglino, PhD (University of Modena and Reggio Emilia)
								</li>
								<li>
									Qiaoli Li, PhD (Thomas Jefferson University-PXE Center of Excellence &amp; Clinical Care)
								</li>
								<li>Kristina Hess Pfau, MD (University of Basel)</li>
								<li>
									Georges Leftheriotis, MD, PhD (Côte d’Azur University-University Hospital Nice)
								</li>
								<li>
									Wilko Spiering, MD, PhD (University Medical Center Utrecht-Dutch Expertise Centre for PXE)
								</li>
								<li>Hubert Chou, MD, PhD (Daiichi Sankyo, Inc.)</li>
								<li>Yves Sabbagh, PhD (Inozyme)</li>
							</ul>
							<p className='mb-0'>
								<b>
									10 AM PT/1 PM ET/19 CET; <span className='color-1e70bf'>4 AEST/7 NZST (Monday)</span>
								</b>
							</p>
							<p className='ml-2em'>
								Closing - onward we go!
								<br />
								Networking - breakout rooms available
								<br />
								We will open breakout rooms - you can ask to be in a room by country/region/ interests
								<br />
								This will be an unmoderated open time.
							</p>
							<p>
								All the talks will be recorded, and all recordings will be available a week after the meeting. We will hold regional meetings throughout the end of 2023 to discuss the workshops.
							</p>
						</div>
                    </Grid.Column>
				</Grid>
				<div className='PXE-hr'></div>
			</Container>
		</>
	)
}

export default GlobalPXEMeeting2022