import React from 'react'
import { Container } from 'semantic-ui-react'
import ReactHtmlParser from 'react-html-parser'

function VolunteerFillContent({ data }) {
	const item = data.find(i => i.link === '/volunteer')
	if (!item) {
		return null; // Return early if no items are found
	}
	return (
		<>
			<Container className='content'>
				{ReactHtmlParser(item.content)}
				<div className='PXE-hr'></div>
			</Container>
		</>
	)
}
export default VolunteerFillContent
