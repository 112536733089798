import React from 'react'
import { Grid, Button } from 'semantic-ui-react'
import './Overview.css'
import ReactHtmlParser from 'react-html-parser'
import ListContentView from './ListContentView'

function OneView(props) {
	const item = props.item
	const listItems = item ? (
		item.haveImage ? (
			<>
				<div className='PXE-hr'></div>
				<Grid.Column>
					<h1 id={item.id}>
						{item.header} <i className='assistive listening systems icon'></i>
					</h1>
				</Grid.Column>
				<Grid.Column>
					<audio controls title={item.audioTitle} className='mb-15'>
						<source src={item.audioSource} type='audio/wav' />
					</audio>
				</Grid.Column>
				<ListContentView contents={item.contentArray} />
			</>
		) : (
			<>
				<div className='PXE-hr'></div>
				<Grid.Column>
					<h1 id={item.id}>
						{item.header} <i className='assistive listening systems icon'></i>
					</h1>
				</Grid.Column>
				<Grid.Column>
					<audio controls title={item.audioTitle} className='mb-15'>
						<source src={item.audioSource} type='audio/wav' />
					</audio>
				</Grid.Column>
				<Grid.Column>{ReactHtmlParser(item.content)}</Grid.Column>
				{item.id && item.id === "research" ? (
					<Button
						color='blue'
						fluid
						animated='fade'
						href='https://id.lunadna.com/referrer/pxe_international?clientId=member-portal&studyName=pxe_international&UTM_source=pxe-website'
					>
						<Button.Content visible className='mb-5 mt-5'>
							Sign Up for PXE International's Registry
						</Button.Content>
						<Button.Content hidden className='mb-5 mt-5'>
							Thank you for your participation!
						</Button.Content>
					</Button>) : null}
			</>
		)
	) : null
	return <>{listItems}</>
}

export default OneView
