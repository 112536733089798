import React from 'react'
import { Grid, Button } from 'semantic-ui-react'
import ReactHtmlParser from 'react-html-parser'

/**
 * Item normal have: content, width
 * @param {*} props
 * @returns
 */
function Item(props) {
	const item = props.item
	const buildItem =
		item && item.id ? (
			<>
				{item.haveSpecialHeader ? (
					<Grid.Column width={item.width ? item.width : 16}>
						<h1 id={item.id}>{item.header}</h1>
					</Grid.Column>
				) : null}
				<Grid.Column width={item.width ? item.width : 16}>
					{ReactHtmlParser(item.content)}
				</Grid.Column>
				{item.special && item.special === 'button' ? (
					<Button
						color='blue'
						fluid
						textAlign='center'
						animated='fade'
						href={item.buttonHref}
						target='_blank'
					>
						<Button.Content visible className='mb-5 mt-5'>
							{item.buttonContent}
						</Button.Content>
						<Button.Content hidden>{item.buttonContentHidden}</Button.Content>
					</Button>
				) : null}
				<div className='PXE-hr'></div>
			</>
		) : null
	return <>{buildItem}</>
}

export default Item
