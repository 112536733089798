import React from 'react'
import { Container, Grid } from 'semantic-ui-react'
import ReactHtmlParser from 'react-html-parser'

function HealthDisclaimerFillContent({ data }) {
	const item = data.find(i => i.link === '/health-disclaimer')
	if (!item) {
		return null; // Return early if no items are found
	}
	return (
		<>
			<Container>
				<Grid>
					<Grid.Column width={16}>
						<h1>
							{item && item.header}
						</h1>
					</Grid.Column>
					<Grid.Column width={16}>
						{ReactHtmlParser(item.content)}
					</Grid.Column>
				</Grid>
				<div className='PXE-hr'></div>
			</Container>
		</>
	)
}

export default HealthDisclaimerFillContent
