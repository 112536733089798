import React from 'react'
import { /* Button ,*/ Container, Grid, /* Segment */ Image } from 'semantic-ui-react'
// import { Link } from 'react-router-dom'

function CelebrateJouni() {
	return (
		<>
			<Container className='content'>
				<Grid columns={1}>
					<Grid.Column>
						<h1>
                            Celebrating Jouni Uitto
                            <br />
                            September 15, 1943- December 19, 2022
                        </h1>
					</Grid.Column>
					<Grid.Column>
                        <Image src='/images/content/JouniUitto.png' centered size='medium' />
                        <figcaption className='text-center mt-15'>
                            <i>Jouni at one of the many PXE International meetings.</i>
                        </figcaption>
                        <p className='mt-40'>
                            This is not a eulogy or memorial since Jouni would have scowled at me and brushed off any attempt to focus on him this way. This is, instead, a celebration of a life and its legacy, scientific and otherwise.
                        </p>
                        <p>
                            When my son Ian’s best friend was dying of glioblastoma multiforme, they were ten years old. Ian said, “Brandon, you can’t go; we are in the middle of <u>the</u> game!” Jouni, we cannot believe you are gone, we are in the middle of the game, and we need you. So many of us feel this way about our dear Jouni. 
                        </p>
                        <p>
                            Jouni Uitto was a brilliant scientist, an energetic and driven team member, a cunning and strategic player in the game of science, and so much more. He was magnanimous, generous, and very funny. There is no way to articulate how much he will be missed. Just weeks before his death, several of his postdocs stopped by my poster at the American Society of Human Genetics and said: “He is a Dad to me. Thank goodness for Jouni in my life.”
                        </p>
                        <p>
                            I met Jouni Uitto a few weeks after my kids were diagnosed with pseudoxanthoma elasticum (PXE). My former husband and I combed through the published literature as 1994 became 1995. We reached out to all the authors for whom we could find contact information. Jouni invited us to his office at Thomas Jefferson University, where, until he died, he was chair of the Department of Dermatology.
                        </p>
                        <p>
                            I can remember the icy day on which we met in 1995, as though it was yesterday. The kids, Elizabeth and Ian, accompanied us as we drove from Sharon, Massachusetts, to his office at Thomas Jefferson University in Philadelphia, PA. They were homeschooled and only 7 and 5 years old, so this was just part of their “curriculum.” We sat in Jouni’s spacious office, with wall-to-wall books, awards, piles of manuscripts, and photos of his family, and told him we needed help.
                        </p>
                        <p>
                            <span style={{ float: 'left', paddingRight: '25px', paddingBottom: '25px' }}>
                                <Image src='/images/content/sharon-and-elizabeth.jpg' size='medium' />
                                <figcaption className='mt-15 text-center' style={{ maxWidth: '300px' }}>
                                    <i>“…and they actually came back with the samples!”</i>
                                </figcaption>
                            </span>
                            <span>
                                In every PXE meeting since, Jouni recounted this meeting with a chuckle and a gleam in his eye, “I told the Terrys to come back with DNA from 400 affected individuals, and I would work on PXE. This is a rare disease, and I figured that was impossible and I would not see them again. To my shock, they came back a month later with more than 400 DNA samples! I got to work, and we have been partners ever since.” We were lay people, uncredentialed in the field, and usually dismissed with a pat on the head. Jouni saw us, and Jouni heard us. Jouni took us seriously from the get-go. He was our primary collaborator and champion as we progressed through various complicated paths in trying to find a treatment for PXE. We not only hacked our way from “nothing is known about this manure-pile of a disease” (toned down quotation from one of the more prominent PXE scientists at the time) through the forest of scientific dead ends but also traveled the world together.
                            </span>
                        </p>
                        <p>
                            He was brilliant and astonishingly productive. I joked that we would go to one of our annual PXE meetings, held on alternate years in Philly or Budapest, and on the way back from the meeting, Jouni would write the meeting summary. We would submit it to a journal a week later. Jouni has nearly 1000 papers indexed in PubMed. Hundreds of these are papers critically important to PXE on everything from the gene associated with PXE to mouse models for therapies in clinical trials. We collaborated on several dozen. We have several in draft form as I write this. Jouni, we have not finished the game. Jouni was the Vice President of Basic Research and Therapy Development for PXE International.
                        </p>
                        <p>
                            I only know a portion of Jouni’s scientific achievements because he was also a giant in the epidermolysis bullosa (EB) field. Epidermolysis bullosa is a group of rare diseases that cause the skin to be fragile and to blister easily. Tears, sores, and blisters in the skin happen when something rubs or bumps the skin. They can appear anywhere on the body. Jouni was a long-standing member of DEBRA of America (the EB research and support organization).
                        </p>
                        <p>
                            <span style={{ float: 'right', paddingLeft: '25px', paddingBottom: '25px' }}>
                                <Image src='/images/content/JouniEgyptianScientist.png' size='medium' />
                                <figcaption className='mt-15 text-center' style={{ maxWidth: '300px' }}>
                                    <i>Jouni appears as the Egyptian scientist come to rock out at the Rock Brokers gala.</i>
                                </figcaption>
                            </span>
                            <span style={{ float: 'right', paddingLeft: '25px', paddingBottom: '25px' }}>
                                <Image src='/images/content/JouniWallStreet.png' centered size='medium' />
                                <figcaption className='text-center mt-15' style={{ maxWidth: '300px' }}>
                                    <i>Jouni at the Rock Broker’s fundraiser on Wall Street.</i>
                                </figcaption>
                            </span>
                            <span>
                                I remember so vividly the PXE International fundraisers in which Jouni participated. Of particular note, Jouni stole the show at the annual “Rock Brokers” bash on Wall Street. Board members and parents of a PXEer, Bob and Kathy Hersey would rev up their friends to garner contributions for a fantastic night of rock and roll and libations. They raised substantial funds that dramatically accelerated research. Kathy and Bob reminisce, “Jouni was a great fan and supporter of the Wall Street charity rock band, “The Rock Brokers.” He often played emcee when they would play fundraising performances for PXE. He was such a big personality, and the crowd would go wild during his enthusiastic introductions on stage. Jouni was so full of life and loved to party with the Wall Street heavyweights. He will be so greatly missed by the Wall Street community.” Not many scientists have pop and scientific field appeal! One year, Jouni came to the bash straight from Egypt sporting a goatee. The crowd went wild that an “Egyptian scientist” had come to greet them. Of course, Jouni could yell out (we had to yell to be heard) several Arabic phrases of greeting.
                            </span>
                        </p>
                        <p>
                            In 2016, Jouni and PXE International established the{' '} 
                            <a
                                href='https://www.jefferson.edu/academics/colleges-schools-institutes/skmc/departments/dermatology/our-research/pxe-center-of-excellence.html'
                                target='_blank'
                                rel='noreferrer'
                            >
                                PXE International Center of Excellence in Research and Clinical Care  
                            </a>
                            {' '}at Jefferson. Phenomenal discoveries are made and supported there. Jouni supported many scientists from all over the world. With PXE International's support, discoveries that have built the foundation for today’s potential therapies for PXE were made. Hundreds of PXEers over the years have supported the center. Earlier this year Jouni was named Vice President of Basic Research and Therapy Development for PXE International. At Jefferson, Jouni was Professor and Chair, Department of Dermatology &amp; Cutaneous Biology, Professor of Biochemistry and Molecular Biology, and Director, Jefferson Institute of Molecular Medicine.
                        </p>
                        <Image src='/images/content/MaryAtJeffersonInstitute.jpg' centered size='big' className='mt-25' />
                        <figcaption className='mt-15 text-center center' style={{ maxWidth: '700px' }}>
                            <i>PXE International’s Mary Peckiconis visits the PXE International Center of Excellence in Research and Clinical Care in 2021. Pictured here are Qiaoli Li, Mary Peckiconis, Jouni Uitto, and Koen van de Wetering.</i>
                        </figcaption>
                        <Image src='/images/content/JouniAndGangWang.jpg' centered size='medium' className='mt-40' />
                        <figcaption className='mt-15 text-center center' style={{ maxWidth: '700px' }}>
                            <i>In 2012, Dr. Gang Wang, Chairman of the Department of Dermatology of the Fourth Military Hospital, Xi’an, honored Jouni with a visiting professorship. We opened a PXE center in Xi’an.</i>
                        </figcaption>
                        <p className='mt-40'>
                            As a result of Jouni’s tenacity and sense of a world community, we have a PXE center in Xi’an and Hebei, China, collaborators in every major European country, and branches of PXE International in South America. Jouni astounded me over and over. We would meet someone; they would tell us in English where they were from -  you name it: Spain, Italy, China, Japan, Brazil, Columbia, France, Hungary, The Netherlands, Slovakia, South Africa, Saudi Arabia, Iraq, Iran, Turkey (you get my drift, and friends from all over the world, do you see yourself in this?) – and Jouni would be off and running in dialogue with them in THEIR language! Jouni put himself through college working as an interpreter. He was the most facile person with languages I have ever met. More importantly, Jouni saw and connected with each of those people, whether scientists of great renown, postdocs, undergrads, PXEers, or the cleaning staff of our hotels. He saw them as people, and they felt seen, just as I had in 1995.
                        </p>
                        <Image src='/images/content/JouniHumorousStory.jpg' centered size='big' className='mt-25' />
                        <figcaption className='text-center mt-15 center' style={{ maxWidth: '700px' }}>
                            <i>Jouni in the midst of a humorous story sprinkled with some Italian for Daniella Quaglino, Professor of Pathology at the University of Modena and Reggio Emilia</i>
                        </figcaption>
                        <p className='mt-40'>
                            Jouni and I had plans. We have several papers that are only in need of final editing. We are finishing some important research projects. We planned to return to China and to also visit our Japanese colleagues. We looked forward to the PXE meeting in Budapest in fall 2023. Andras Varadi, the host of the meeting when it is in Budapest on alternate years says, “I am so sad, I have no words for how sad I am. Jouni was not only a great scientist but a great character. Always ready for fun, for jokes, so full of energy. This is a big loss for all of us! The 2023 Budapest PXE-Calcification Meeting is scheduled to October 1 – 3. The meeting will be dedicated to the memory of Jouni Uitto, the co-organizer of the meeting (together with Sharon Terry of PXE International).” 
                        </p>
                        <p>
                            Mark Lebwohl, a long-time collaborator of Jouni’s and PXE International’s, said, “I am devastated. He was an incredible, unsung, hero. No one will be able to take his place. For the PXE community, this is devastating. We can’t let what he started fail, especially when PXE is so close to being treatable. I want to be the first to contribute to the Jouni Uitto Legacy Fund.”
                        </p>
                        <p>
                            Jouni, I miss you. I turn a corner in my PXE work and expect your larger-than-life self to be there. I get excited about some new idea, and I start to write an email to tell you. I come upon a conundrum, and I reach for the phone to ask your advice. I am trying to figure out how to navigate the coming days, weeks, and years. I am trying to figure out how to channel your inquisitive mind, and demanding standards. I dedicate my work in PXE to you, to the hundreds of thousands who know the pain and disability of living with this condition. We are filled with gratitude for the foundation you have given us. We go forward carried by your work, your love of life, and your great compassion for those who suffer.
                        </p>
                        <Image src='/images/content/SharonAndJouni.jpg' centered size='large' className='mt-25' />
                        <figcaption className='text-center mt-15 center' style={{ maxWidth: '700px' }}>
                            <i>Sharon and Jouni after a delicious Italian meal at the PXE International 2018 meeting. We worked so hard together, and Jouni always knew how to find a wonderful meal and some good wine. Goodbye, my friend.</i>
                        </figcaption>
                        <p className='mt-40'>
                            Our condolences to Jouni’s family for this tremendous  loss of a husband, father, and grandfather. Our sympathy to Jefferson community.
                        </p>
                        <p>
                            At the request of Jouni’s colleagues, PXE International invites you to remember Jouni through a donation Jouni’s honor, to the{' '} 
                            <a
                                href='https://www.paypal.com/donate/?hosted_button_id=CUKP8D53NYLUN'
                                target='_blank'
                                rel='noreferrer'
                            >
                                Jouni Uitto Legacy Fund
                            </a>
                            . You can also share your own thoughts about Jouni there.
                        </p> 
                    </Grid.Column>
				</Grid>
				<div className='PXE-hr'></div>
                <p>
                    Hear from Jouni why the PXE International Center of Excellence in Research and Clinical Care at Jefferson is critical:
                </p>
                <p className='center'>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/VJ_K18szfW0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </p>
                
                <p className='mt-25'>
                    For more about Jouni’s early life and scientific achievements visit:{' '}
                    <a
                        href='https://nexus.jefferson.edu/science-and-technology/renowned-researcher-visits-u-s-for-a-year-stays-a-life-time/'
                        target='_blank'
                        rel='noreferrer'
                    >
                        https://nexus.jefferson.edu/science-and-technology/renowned-researcher-visits-u-s-for-a-year-stays-a-life-time/
                    </a>
                </p>
				<div className='PXE-hr'></div>
			</Container>
		</>
	)
}

export default CelebrateJouni