import React from 'react'
import { Container, Grid } from 'semantic-ui-react'

function NotFound() {
	return (
		<>
			<Container className='content'>
				<Grid>
					<Grid.Column width={16}>
						<h1>404: Page Not Found</h1>
					</Grid.Column>
					<p>
						It looks like the page you are attempting to access either does not exist,
						or is located at a different url. Please try accessing the page again, if
						this issue continues contact the webmaster at{' '}
						<a href='mailto:info@pxe.org'>Info@PXE.org</a>.{' '}
					</p>
					<p>Thank you, and sorry for the inconvenience</p>
					<Grid.Column width={16}></Grid.Column>
				</Grid>
				<div className='PXE-hr'></div>
			</Container>
		</>
	)
}

export default NotFound
