import { SearchIcon } from '../../../svg'

export const MenuData = [
	{
		title: 'About PXE',
		path: '/overview',
	},
	{
		title: 'News',
		path: '/news',
	},
	{
		title: 'Research',
		path: '/research',
	},
	{
		title: 'About Us',
		path: '/about-us',
		children: [
			{
				title: 'Board Members',
				path: '/about-us/board-members',
			},
			{
				title: 'Staff',
				path: '/about-us/staff',
			},
			{
				title: 'Financials',
				path: '/about-us/financials',
			},
		],
	},
	{
		title: 'Events',
		path: '/events',
	},
	{
		title: 'Volunteer',
		path: '/volunteer',
	},
	{
		title: SearchIcon,
		path: '/search',
	},
]
