import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Container, Input } from 'semantic-ui-react'
import * as Realm from 'realm-web'

function Search() {
	const [searchTerm, setSearchTerm] = useState('')
	const [showFoundData, setShowFoundData] = useState([])
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		setSearchTerm(localStorage.getItem('search') || '')
	}, [])

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			if (searchTerm) {
				setIsLoading(true)
				const app = new Realm.App({ id: 'pxe-cms-application-gazfs' })
				const credentials = Realm.Credentials.anonymous()

				async function searchPxeCms() {
					try {
						const data = await app.logIn(credentials)
						const searchData = await data.functions.searchPxeCms(searchTerm)
						const result = []

						searchData.forEach((i, index) => {
							if (
								i.link === '/about-us/staff' ||
								i.link === '/about-us/board-members'
							) {
								if (i.link && i.menu && i.header) {
									result.push({
										_id: i._id + index,
										link: i.linkURL ? i.linkURL : i.link + (i.id ? `#${i.id}` : ''),
										header: i.header,
										menu: i.menu,
									})
									// console.log('staff', i);
								}
							} else {
								if (i.link && i.menu && i.header) {
									result.push({
										_id: i._id + index,
										link: i.link + (i.id ? `#${i.id}` : ''),
										header: i.header,
										menu: i.menu,
									})
									// console.log('i2', i);
								}
							}
						})

						setShowFoundData(result)
					} catch (err) {
						console.error('Failed to log in', err)
					} finally {
						setIsLoading(false)
					}
				}

				localStorage.setItem('search', searchTerm)
				searchPxeCms()
			}
		}, 500)

		return () => clearTimeout(delayDebounceFn)
	}, [searchTerm])

	return (
		<Container className='content'>
			<h1>Search Results:</h1>
			<Input
				icon='search'
				placeholder='Search...'
				value={searchTerm}
				autoComplete='off'
				onChange={e => setSearchTerm(e.target.value)}
			/>
			{searchTerm !== '' && (
				<>
					<p className='mt-25'>
						<strong>
							Results for: "{searchTerm}" | {showFoundData.length || 0} result(s)
						</strong>
					</p>
					{showFoundData.length > 0 && (
						<ul className=''>
							{showFoundData.map(item => (
								<li key={item['_id']}>
									<Link to={item.link}>
										{item.menu ? `${item.menu} - ` : ''} {item.header}
									</Link>
								</li>
							))}
						</ul>
					)}
				</>
			)}
			{isLoading && searchTerm !== '' && (
				<p className='mt-25'>Searching data...</p>
			)}
			{!showFoundData.length && !isLoading && (
				<p>There are no results for this search.</p>
			)}

			<p className='mt-25'>
				<strong>Other resources you may look for:</strong>
			</p>
			<ul className=''>
				<li>
					<Link to='/news'>PXE International News</Link>
				</li>
				<li>
					<Link to='/events'>PXE International Events</Link>
				</li>
				<li>
					<Link to='/about-us/financials'>PXE Financials</Link>
				</li>
				<li>
					<Link to='/contact-us'>Contact PXE</Link>
				</li>
			</ul>

			<div className='PXE-hr'></div>
		</Container>
	)
}

export default Search
