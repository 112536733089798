import React from 'react'
import { List } from 'semantic-ui-react'
import './Overview.css'

function ListItems(props) {
	const listItems = props.listItems
	const items = []

	if (listItems && Array.isArray(listItems) && listItems.length > 0) {
		for (let order = 0; order <= listItems.length; order++) {
			const matchingItem = listItems.find(item => item.order === order)
			if (matchingItem) {
				items.push(
					<List.Item
						key={matchingItem.order}
						as='a'
						href={matchingItem.href}
						className={matchingItem.clazzName ? matchingItem.clazzName : ''}
						target={matchingItem.target ? matchingItem.target : ''}
					>
						{matchingItem.content}
					</List.Item>
				)
			}
		}
	}

	return <>{items}</>
}

export default ListItems
