import React from 'react'
import { /* Button ,*/ Container, Grid, /* Segment */ } from 'semantic-ui-react'
// import { Link } from 'react-router-dom'

function PXEResearchMeeting2022() {
	return (
		<>
			<Container className='content'>
				<Grid columns={1}>
					<Grid.Column>
						<h1>PXE Research Meeting 2022</h1>
					</Grid.Column>
					<Grid.Column>
                        <h2>Coming Soon...</h2>
                    </Grid.Column>
				</Grid>
				<div className='PXE-hr'></div>
			</Container>
		</>
	)
}

export default PXEResearchMeeting2022