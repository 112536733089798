import './App.css'
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from 'react-router-dom'
import { ScrollToTop } from './components/helpers/ScrollToTop'
import Header from './components/layout/Header'
import Footer from './components/layout/footer/Footer'
import Home from './components/pages/home/HomeFillContent'
import Overview from './components/pages/overview/OverviewFillContent'
import News from './components/pages/news/News'
import Research from './components/pages/research/ResearchFillContent'
import AboutUs from './components/pages/aboutUs/AboutUsFillContent'
import Events from './components/pages/events/Events'
import Search from './components/pages/search/Search'
import PrivacyPolicy from './components/pages/privacyPolicy/PrivacyPolicyFillContent'
import HealthDisclaimer from './components/pages/healthDisclaimer/HealthDisclaimerFillContent'
import NotFound from './components/pages/notFound/NotFound'
import Meeting from './components/pages/events/meeting/Meeting'
import BoardMembers from './components/pages/aboutUs/boardMembers/BoardMembersFillContent'
import Staff from './components/pages/aboutUs/staff/StaffFillContent'
import Financials from './components/pages/aboutUs/financials/Financials'
import Volunteer from './components/pages/volunteer/VolunteerFillContent'
import PXEResearchMeeting2022 from './components/pages/events/PXEResearchMeeting2022'
import GlobalPXEMeeting2022 from './components/pages/events/GlobalPXEMeeting2022'
import CelebrateJouni from './components/pages/news/CelebrateJouni'
// import BarbaraDrewicz from './components/pages/aboutUs/boardMembers/BarbaraDrewicz'
// import JessicaHarper from './components/pages/aboutUs/boardMembers/JessicaHarper'
// import JoeMaas from './components/pages/aboutUs/boardMembers/JoeMaas'
// import SamHarper from './components/pages/aboutUs/boardMembers/SamHarper'
// import KathyHersey from './components/pages/aboutUs/boardMembers/KathyHersey'
// import LionelBercovitch from './components/pages/aboutUs/boardMembers/LionelBercovitch'
// import SharonTerry from './components/pages/aboutUs/boardMembers/SharonTerry'
// import StaffSharonTerry from './components/pages/aboutUs/staff/StaffSharonTerry'
// import StaffMaryPeckiconis from './components/pages/aboutUs/staff/StaffMaryPeckiconis'
// import StaffCatherineTroutman from './components/pages/aboutUs/staff/StaffCatherineTroutman'
// import StaffAnnaSusanMarais from './components/pages/aboutUs/staff/StaffAnnaSusanMarais'
import OneStaffByData from './components/pages/aboutUs/staff/OneStaffByData'
import ContactUs from './components/pages/contactUs/ContactUs'
import * as Realm from 'realm-web'
import { useEffect, useState } from 'react'

function App() {
	const [data, setData] = useState([])

	useEffect(() => {
		const getData = async () => {
			const app = new Realm.App({ id: 'pxe-cms-application-gazfs' })
			const credentials = Realm.Credentials.anonymous()
			try {
				const data = await app.logIn(credentials)
				const pxeCmsData = await data.functions.getPxeCms()
				// console.log('pxeCmsData', pxeCmsData);
				setData(pxeCmsData)
			} catch (err) {
				console.error('Failed to log in', err)
			}
		}
		getData()
	}, [])

	return (
		<Router>
			<ScrollToTop />
			<Header />
			<Routes>
				<Route path='/' element={<Home data={data} />} />
				<Route path='/overview' element={<Overview data={data} />} />
				<Route path='/news/celebrating-jouni-uitto' element={<CelebrateJouni />} />
				<Route path='/news' element={<News />} />
				<Route path='/registry' element={<Navigate to='/research' />} />
				<Route path='/research' element={<Research data={data} />} />
				<Route path='/about-us' element={<AboutUs data={data} />} />
				<Route
					path='/about-us/board-members'
					element={<BoardMembers data={data} />}
				/>
				<Route
					path='/about-us/board-members/barbara-drewicz'
					element={
						<OneStaffByData
							data={data}
							linkURL='/about-us/board-members/barbara-drewicz'
						/>
					}
				/>
				<Route
					path='/about-us/board-members/jessica-harper'
					element={
						<OneStaffByData
							data={data}
							linkURL='/about-us/board-members/jessica-harper'
						/>
					}
				/>
				<Route
					path='/about-us/board-members/joe-maas'
					element={
						<OneStaffByData data={data} linkURL='/about-us/board-members/joe-maas' />
					}
				/>
				<Route
					path='/about-us/board-members/kathy-hersey'
					element={
						<OneStaffByData
							data={data}
							linkURL='/about-us/board-members/kathy-hersey'
						/>
					}
				/>
				<Route
					path='/about-us/board-members/lionel-bercovitch'
					element={
						<OneStaffByData
							data={data}
							linkURL='/about-us/board-members/lionel-bercovitch'
						/>
					}
				/>
				<Route
					path='/about-us/board-members/sam-harper'
					element={
						<OneStaffByData
							data={data}
							linkURL='/about-us/board-members/sam-harper'
						/>
					}
				/>
				<Route
					path='/about-us/board-members/sharon-terry'
					element={
						<OneStaffByData
							data={data}
							linkURL='/about-us/board-members/sharon-terry'
						/>
					}
				/>
				<Route
					path='/about-us/staff/sharon-terry'
					element={
						<OneStaffByData
							data={data}
							linkURL='/about-us/staff/sharon-terry'
							isStaff={true}
						/>
					}
				/>
				<Route
					path='/about-us/staff/mary-peckiconis'
					element={
						<OneStaffByData
							data={data}
							linkURL='/about-us/staff/mary-peckiconis'
							isStaff={true}
						/>
					}
				/>
				<Route
					path='/about-us/staff/catherine-troutman'
					element={
						<OneStaffByData
							data={data}
							linkURL='/about-us/staff/catherine-troutman'
							isStaff={true}
						/>
					}
				/>
				<Route
					path='/about-us/staff/anna-susan-marais'
					element={
						<OneStaffByData
							data={data}
							linkURL='/about-us/staff/anna-susan-marais'
							isStaff={true}
						/>
					}
				/>
				<Route path='/about-us/staff' element={<Staff data={data} />} />
				<Route path='/about-us/financials' element={<Financials />} />
				<Route
					path='/events/2021-international-conference-sched/'
					element={<Navigate to='/events/meeting' />}
				/>
				<Route path='/events/meeting' element={<Meeting />} />
				<Route
					path='/index.php/pxe-international-events/'
					element={<Navigate to='/events' />}
				/>
				<Route
					path='/events/global-meeting-2022'
					element={<GlobalPXEMeeting2022 />}
				/>
				<Route
					path='/events/research-meeting-2022'
					element={<PXEResearchMeeting2022 />}
				/>
				<Route path='/events' element={<Events data={data} />} />
				<Route path='/volunteer' element={<Volunteer data={data} />} />
				<Route path='/contact-us' element={<ContactUs />} />
				<Route path='/search' element={<Search />} />
				<Route path='/privacy-policy' element={<PrivacyPolicy data={data} />} />
				<Route
					path='/health-disclaimer'
					element={<HealthDisclaimer data={data} />}
				/>
				<Route path='/donate/' element={<Navigate to='/' />} />
				<Route path='/page-not-found' component={<NotFound />} />
				<Route path='*' element={<Navigate to='page-not-found' />} />
			</Routes>
			<Footer />
		</Router>
	)
}

export default App
